import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy around the coding of database dredentials.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Database authentication credentials are a necessary part of authorizing
application to connect to internal databases. However, incorrect use,
storage and transmission of such credentials could lead to compromise of
very sensitive assets and be a springboard to wider compromise within
the organization.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`This policy states the requirements for securely storing and retrieving
database usernames and passwords (i.e., database credentials) for use by
a program that will access a database running on one of Data Migrators\\‘s networks.`}</p>
    <p>{`Software applications running on Data Migrators’ networks may
require access to one of the many internal database servers. In order to
access these databases, a program must authenticate to the database by
presenting acceptable credentials. If the credentials are improperly
stored, the credentials may be compromised leading to a compromise of
the database.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy is directed at all system implementer and/or software
engineers who may be coding applications that will access a production
database server on the Data Migrators Network. This policy applies to
all software (programs, modules, libraries or APIS that will access a
Data Migrators, multi-user production database. It is recommended that
similar requirements be in place for non-production servers and lap
environments since they don’t always use sanitized information.`}</p>
    <h2>{`Policy`}</h2>
    <h3>{`General`}</h3>
    <p>{`In order to maintain the security of Data Migrators’ internal
databases, access by software programs must be granted only after
authentication with credentials. The credentials used for this
authentication must not reside in the main, executing body of the
program\\‘s source code in clear text. Database credentials must not be
stored in a location that can be accessed through a web server.`}</p>
    <h3>{`Specific Requirements`}</h3>
    <p>{`Storage of Data Base User Names and Passwords`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Database user names and passwords may be stored in a file separate
from the executing body of the program\\‘s code. This file must not
be world readable or writeable.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Database credentials may reside on the database server. In this
case, a hash function number identifying the credentials may be
stored in the executing body of the program\\‘s code.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Database credentials may be stored as part of an authentication
server (i.e., an entitlement directory), such as an LDAP server used
for user authentication. Database authentication may occur on behalf
of a program as part of the user authentication process at the
authentication server. In this case, there is no need for
programmatic use of database credentials.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Database credentials may not reside in the documents tree of a web
server.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Pass through authentication (i.e., Oracle OPS\\$ authentication) must
not allow access to the database based solely upon a remote user\\‘s
authentication on the remote host.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Passwords or pass phrases used to access a database must adhere to
the `}<em parentName="p">{`Password Policy`}</em>{`.`}</p>
      </li>
    </ul>
    <h3>{`Retrieval of Database User Names and Passwords`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If stored in a file that is not source code, then database user
names and passwords must be read from the file immediately prior
to use. Immediately following database authentication, the memory
containing the user name and password must be released or cleared.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The scope into which you may store database credentials must be
physically separated from the other areas of your code, e.g., the
credentials must be in a separate source file. The file that
contains the credentials must contain no other code but the
credentials (i.e., the user name and password) and any functions,
routines, or methods that will be used to access the credentials.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For languages that execute from source code, the credentials\\’
source file must not reside in the same browseable or executable
file directory tree in which the executing body of code resides.`}</p>
      </li>
    </ul>
    <h3>{`Access to Database User Names and Passwords`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Every program or every collection of programs implementing a single
business function must have unique database credentials. Sharing of
credentials between programs is not allowed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Database passwords used by programs are system-level passwords as
defined by the `}<em parentName="p">{`Password Policy`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Developer groups must have a process in place to ensure that
database passwords are controlled and changed in accordance with the
`}<em parentName="p">{`Password Policy`}</em>{`. This process must include a method for
restricting knowledge of database passwords to a need-to-know basis.`}</p>
      </li>
    </ul>
    <h3>{`Techniques for implementing this policy`}</h3>
    <p>{`All Data Migrators staff should utilise 1Password for gnerating passwords.`}</p>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, business tool reports, internal
and external audits, and feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <p>{`A violation of this policy by a temporary worker, contractor or vendor
may result in the termination of their contract or assignment with
Data Migrators.`}</p>
    <p>{`Any program code or application that is found to violate this policy
must be remediated within a 90 day period.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "password_construction_guidelines"
        }}>{`Password Policy`}</a></li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`The following definition and terms can be found in the `}<a parentName="p" {...{
        "href": "https://www.sans.org/security-resources/glossary-of-terms/"
      }}>{`SANS Glossary`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`Credentials`}</li>
      <li parentName="ul">{`Executing Body`}</li>
      <li parentName="ul">{`Hash Function`}</li>
      <li parentName="ul">{`LDAP`}</li>
      <li parentName="ul">{`Module`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      